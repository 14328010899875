import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const DataProcessingAddendum = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Data Processing Addendum'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="data-processing-addendum" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="hero-wrapper text-center width-medium">
                              <h1>Data Processing Addendum</h1>
                              <div class="sub-text">
                                   <p>Last updated: January 24, 2025</p>
                              </div>
                         </div>
                         <div className="platform-intro-content">
                              <h3>Introduction</h3>
                              <p>
                                   This Data Processing Addendum ("DPA") governs how RealProof, Inc., doing business as ListenLayer (“we,” “our,” or
                                   “us”) processes Customer Data provided by the Customer (“you”) through your use of our Software or Services. This
                                   Data Processing Addendum is incorporated into and made part of our general{' '}
                                   <a href="/terms-of-use/">Terms of Service</a> and shares many of the same defined terms (which are capitalized in
                                   this DPA). For the purposes of this DPA, “Customer” refers to any individual, agent, affiliated entity or
                                   individual, business, or organization that utilizes the Software or Services provided by us.
                              </p>

                              <p>
                                   Within this DPA, "Customer Data" refers to any information, including personal data, which is provided to
                                   ListenLayer by or on behalf of the Customer, or is collected by ListenLayer on the Customer's behalf, in the course
                                   of providing the Services or using the Software and is inclusive of Your Business Data and Your Personal Data.
                              </p>
                              <h3>Compliance with Applicable Laws</h3>
                              <p className="no-padding">
                                   ListenLayer and Customer each agree to comply with their respective obligations under applicable data privacy and
                                   protection laws (collectively, “Data Privacy Laws”) in connection with the Software and Services. Data Privacy Laws
                                   may include, but are not limited to:
                              </p>
                              <ul>
                                   <li>The General Data Protection Regulation (or “GDPR”) within the European Union</li>
                                   <li>The UK version of the General Data Protection Regulation (or “UK GDPR”)</li>
                                   <li>
                                        California Consumer Privacy Act of 2018 and California Privacy Rights Act of 2023 (“California Privacy Laws”)
                                   </li>
                                   <li>The Colorado Privacy Act (or “CPA”)</li>
                                   <li>The Virginia Consumer Data Protection Act (or “VCDPA”)</li>
                                   <li>The Utah Consumer Privacy Act (“UCPA”)</li>
                                   <li>The Connecticut Data Privacy Act (“CTDPA”)</li>
                                   <li>The Delaware Personal Data Privacy Act (“DPDPA”)</li>
                                   <li>The Iowa Consumer Data Protection Act (“ICDPA”)</li>
                                   <li>The Montana Consumer Data Privacy Act (“MT CDPA”)</li>
                                   <li>The Oregon Consumer Privacy Act (“OCPA”)</li>
                                   <li>The Texas Data Privacy and Security Act (“TDPSA”)</li>
                                   <li>The Minnesota Consumer Data Privacy Act (“MCDPA”)</li>
                                   <li>The Tennessee Information Protection Act (“TIPA”)</li>
                                   <li>
                                        Any subsequent amendments, revisions, or replacements of the aforementioned laws, as well as any other data
                                        privacy and protection laws enacted by states within the USA or other jurisdictions where either party
                                        operates.
                                   </li>
                              </ul>
                              <h3>Roles Under Applicable Laws</h3>
                              <p className="no-padding">With respect the Data Privacy Laws, ListenLayer and Customer hold the following roles:</p>
                              <ul>
                                   <li>
                                        <b>Customer:</b> The entity that determines the purpose and means for which the Customer Data is collected,
                                        processed and stored. This role is referred to herein as the “Data Controller” and may be referred to using
                                        similar terms, such as, but not limited to “Business” and “Controller” within the Data Privacy Laws.
                                   </li>
                                   <li>
                                        <b>ListenLayer:</b> The entity that processes Customer Data in accordance with the Data Controller’s
                                        instructions and on behalf of the Data Controller. This role is referred herein as the “Data Processor” and
                                        may be referred to using similar terms, such as, but not limited to, “Service Provider” and “Processor” within
                                        the Data Privacy laws.
                                   </li>
                              </ul>
                              <p>
                                   ListenLayer and Customer acknowledge and agree that they are not, and will not act as joint controllers with
                                   respect to the Customer Data, as defined under the Data Privacy Laws. Both parties confirm that there is a clear
                                   distinction between the roles and responsibilities of ListenLayer as the Data Processor and the Customer as the
                                   Data Controller. Neither ListenLayer nor Customer has made, or is relying upon, any representations, warranties, or
                                   other assertions suggesting or implying a joint controllership relationship concerning Customer Data under this
                                   DPA.
                              </p>
                              <h3>Acknowledgment of Personal Data in Customer Data</h3>
                              <p>
                                   The Customer acknowledges and understands that their use of the ListenLayer platform, within their capacity as the
                                   Data Controller, will result in the collection and processing of Customer Data, which may encompass Personal Data
                                   pertaining to natural persons or households. For the purposes of this DPA, “Personal Data” is defined as any
                                   information relating to a natural person or household that can identify or make identifiable such entities, either
                                   in isolation or in conjunction with other information, which ListenLayer will process or have exposure to in the
                                   course of providing the Services, including any data generated through the Services. This definition of Personal
                                   Data aligns with the terminologies as specified under the Data Privacy Laws referenced in this agreement.
                              </p>
                              <h3>Purpose and Use of Customer Data</h3>
                              <p>
                                   <b>Purpose:</b> ListenLayer processes Customer Data solely for the purpose of fulfilling its obligations as set out
                                   in the Terms of Service and to provide the Services as instructed by the Customer and as defined in the Terms of
                                   Service. These constitute the Business Purpose for retaining, processing, and using such data.{' '}
                              </p>
                              <p className="no-padding">
                                   <b>Use and Disclosure:</b> ListenLayer will never sell, share, distribute, or commercialize Personal Data or
                                   Customer Data, and will not retain, use, or disclose such data outside of the Business{' '}
                              </p>
                              <p className="no-padding">Purpose and our relationship with you except:</p>
                              <ol>
                                   <li>As permitted by the Terms of Service in order to provide the Service</li>
                                   <li>
                                        As explicitly instructed by the Customer either in writing or through the Customer’s direct actions taken in
                                        using the Software
                                   </li>
                                   <li>
                                        When strictly necessary for our internal administrative purposes related to the provision of our Software or
                                        Services
                                   </li>
                                   <li>As required by law, in which case we will promptly notify you of such required action</li>
                              </ol>
                              <p>
                                   <b>Confidentiality:</b> ListenLayer will require any persons engaged with our business to be subject to a duty of
                                   confidentiality with respect to Customer Data and to comply with data protection obligations applicable to
                                   ListenLayer under this DPA.
                              </p>
                              <p>
                                   <b>Sub-processors:</b> ListenLayer may engage Sub-processors to assist in the delivery of the Services. A
                                   comprehensive list of Sub-processors and their respective roles can be found at{' '}
                                   <a href="/sub-processors" target="_blank">
                                        https://www.listenlayer.com/sub-processors
                                   </a>
                                   . The Customer grants ListenLayer the authorization to engage such Sub-processors, with the commitment that any
                                   changes to this list will be communicated to the Customer in a timely manner, offering the Customer an opportunity
                                   to raise objections. ListenLayer ensures that all Sub-processors are contractually bound to uphold data protection
                                   standards that are at least as stringent as those stipulated in this DPA and the Terms of Service. ListenLayer
                                   retains full accountability for the actions and commitments of its Sub-processors, subject to the limitations of
                                   liability set out in the Terms of Service.
                              </p>
                              <p>
                                   <b>Demonstration of Compliance:</b> Upon reasonable notice, no more often than every 12 months, and where required
                                   by law, Customer may require security policies and other information necessary to demonstrate ListenLayer’s
                                   compliance with obligations set forth in this DPA. ListenLayer will cooperate with such requests by providing
                                   applicable policies and summary findings from audits and certifications, all of which is considered Confidential
                                   Information, and all of which will be provided at the Customer’s sole expense. In the event that a Customer
                                   requested audit is required, such audits will take place in a manner that causes minimal disruption to
                                   ListenLayer’s business, and all expenses for such audit will be covered by the Customer.
                              </p>
                              <p>
                                   <b>Combining Data:</b> Where Customer Data is subject to California Privacy Laws, we will not combine such data
                                   with personal information that we collect or receive from another source (other than information from another
                                   source in connection with your use of the Service or our obligations to you under the Terms of Service). We certify
                                   that we treat Personal Data with the same level of privacy protection as required by California Privacy Laws and
                                   applicable US privacy Law.
                              </p>
                              <p>
                                   <b>Notifications:</b> Whenever necessary, you may take reasonable and appropriate steps to ensure that we use
                                   Customer Data collected from your use of the Service in a manner consistent with applicable laws. Upon providing us
                                   with notice, you may take the necessary action to stop and remediate unauthorized use of Customer Data, for example
                                   by requesting confirmation that applicable data has been deleted. ListenLayer will notify you, if at any time we
                                   are unable to fulfill our obligations under this DPA.
                              </p>
                              <h3>Data Return and Deletion</h3>
                              <p>
                                   <b>Duration and Termination:</b> This DPA remains in effect for as long as ListenLayer conducts data processing
                                   operations on behalf of the Customer or until the termination of the Terms of Service. Once the Terms of Service
                                   are terminated, all obligations under this DPA shall cease, except as noted in this section regarding data return
                                   and deletion.
                              </p>
                              <p>
                                   <b>Data Retention:</b> ListenLayer will retain Customer Data for as long as is necessary to provide the Services in
                                   line with the purposes defined in the Terms of Service and this DPA. However, if specific laws mandate ListenLayer
                                   to retain copies of the Customer Data, the data will be isolated and safeguarded against any additional processing,
                                   except as required by such applicable laws.
                              </p>
                              <p>
                                   <b>Return or Deletion upon Termination:</b> Upon the termination of this DPA and the Terms of Service, ListenLayer
                                   will, if required by applicable Data Privacy Laws, return the Customer Data to the Customer. If not required by
                                   law, ListenLayer will proceed to delete the Customer Data in a manner and timeline consistent with applicable legal
                                   and operational considerations.
                              </p>
                              <p>
                                   <b>Sub-processors:</b> ListenLayer will instruct each Sub-processor to delete the Customer Data within a reasonable
                                   period following the DPA's termination, unless such deletion is prohibited by applicable laws.
                              </p>
                              <p>
                                   <b>Continued Processing of Deidentified Data:</b> For clarity, ListenLayer may continue to process information
                                   derived from Customer Data that has undergone deidentification, anonymization, or aggregation. Such data, no longer
                                   deemed as Personal Data under applicable Data Privacy Laws, will be processed in a manner that neither identifies
                                   individuals nor the Customer. This processing is conducted to enhance and refine ListenLayer’s Software and
                                   Services.
                              </p>
                              <h3>Responsibilities of the Customer</h3>
                              <p>
                                   <b>Customer Representations:</b> You, the Customer, assert and guarantee that you possess, and will continue to
                                   possess for the duration of this agreement, all necessary rights, consents, and authorizations to supply Customer
                                   Data to ListenLayer. You further authorize ListenLayer to utilize, disclose, store, and process the Customer Data
                                   as outlined in this DPA, the Terms of Service, and any other processing directives provided to ListenLayer.
                              </p>
                              <p>
                                   <b>Compliance with Data Privacy Laws:</b> You are obligated to adhere to all relevant Data Privacy Laws.
                              </p>
                              <p>
                                   <b>Cooperation with ListenLayer:</b> In your capacity as the Customer, you shall offer reasonable assistance to
                                   ListenLayer in fulfilling its duties concerning requests from data subjects, which are the individual, natural
                                   persons identified within your data.
                              </p>
                              <p>
                                   <b>Customer’s Responsibility for Security and Compliance:</b> Notwithstanding ListenLayer's security commitments in
                                   the Section titled Security Measures of this DPA, you acknowledge that you are responsible for specific
                                   configurations and decisions regarding the Software’s setup. You agree to implement these configurations and
                                   decisions securely, in line with applicable Data Privacy Laws, recognizing that this responsibility lies with you
                                   and not ListenLayer.
                              </p>
                              <p>
                                   <b>Controlled Transfer of Customer Data:</b> You shall only provide Customer Data to ListenLayer through mutually
                                   agreed-upon mechanisms. You commit to transferring Customer Data to ListenLayer using secure and appropriate
                                   methods.
                              </p>
                              <p>
                                   <b>No Compensation for Data:</b> ListenLayer has not provided you with remuneration in exchange for Customer Data
                                   or Personal Data and you agree that you have not sold such data to us. Furthermore, you agree that you will not
                                   take any action which would render our processing of such data to be considered “selling” or “sharing” under
                                   relevant Data Privacy Laws.
                              </p>
                              <h3>Security Measures</h3>
                              <p>
                                   <b>Implementation of Security Measures:</b> ListenLayer commits to maintaining robust and suitable organizational
                                   and technical security measures. These measures encompass various aspects including personnel, facilities,
                                   hardware, software, data storage, network systems, access controls, along with monitoring and logging practices.
                                   Additionally, ListenLayer will implement effective vulnerability and breach detection, incident response
                                   strategies, and encryption technologies. The primary goal of these measures is to safeguard against unauthorized or
                                   accidental access, loss, modification, disclosure, or destruction of Customer Data, and to protect the rights of
                                   the individuals represented in that Customer Data.
                              </p>
                              <p>
                                   <b>Personnel Security Obligations:</b> ListenLayer will take diligent steps to ensure that our personnel are
                                   upholding the security, privacy, and confidentiality of Customer Data in alignment with the stipulations of this
                                   DPA.
                              </p>
                              <p>
                                   <b>Breach Notification:</b> In the event of a Personal Data breach involving ListenLayer, our Sub-processors, or
                                   any other third parties acting on behalf of ListenLayer, we will promptly notify you without undue delay upon
                                   becoming aware of such a breach. This notification aims to keep you informed and enable you to take necessary
                                   protective measures in accordance with applicable Data Privacy Laws and the terms of this DPA.
                              </p>
                              <h3>Standard Contract Clauses</h3>
                              <p className="no-padding">
                                   ListenLayer will process Personal Data and Customer Data that originates in the European Economic Area in
                                   accordance with the Standard Contractual Clauses (SCCs) adopted by the EU Commission on June 4, 2021 (“EU SCCs”)
                                   which are deemed entered into (and incorporated into this DPA by this reference) and may be found at the following
                                   location:
                              </p>
                              <ul>
                                   <li>
                                        <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj" target="_blank">
                                             https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj
                                        </a>
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   Exhibit A to this DPA contains the information required in Annex I and Annex III of the SCCs.
                              </p>
                              <p>Exhibit B to this DPA contains the information required in Annex II of the SCCs. </p>
                              <p>
                                   <b>Switzerland Addendum:</b> Customer Data originating from Switzerland shall be processed in accordance with EU
                                   SCC, which shall be interpreted in a manner to ensure compliance with the FADP.
                              </p>
                              <p>
                                   <b>United Kingdom Addendum:</b> For the processing of Customer Data originating from the United Kingdom, both
                                   parties agree to comply with the terms of Part 2: Mandatory Clauses of the Approved Addendum, as defined by the
                                   template Addendum B.1.0 issued by the Information Commissioner’s Office (ICO). This Addendum was established in
                                   accordance with Section 119A of the Data Protection Act 2018 on February 2, 2022, and is subject to any revisions
                                   as outlined in Section 18 of those Mandatory Clauses, collectively referred to as the “UK Addendum.” Additionally,
                                   either party may terminate the UK Addendum following the procedures set out in Section 19 of the UK Addendum. This
                                   compliance approach ensures that the transfer and processing of Customer Data from the United Kingdom are in strict
                                   accordance with UK Data Privacy Laws.
                              </p>
                              <h3>Exhibit A</h3>
                              <p className="no-padding">Information Required under Annex I and Annex III of the SCCs:</p>
                              <p>
                                   <b>Data Exporter Details:</b> The Customer, as defined in this DPA and the Terms of Service, as well as
                                   identification information provided during registration of a Business Account.
                              </p>
                              <p className="no-padding">
                                   <b>Data Importer Details:</b>
                              </p>
                              <ul>
                                   <li>Name: RealProof Inc. dba ListenLayer</li>
                                   <li>Address: 6855 S Dayton St #5456, Greenwood Village, CO 80112</li>
                                   <li>
                                        Contact Person’s Name, Position, and Contact Details: Zachary Randall, Chief Executive Officer,{' '}
                                        <a href="mailto:support@listenlayer.com" target="_blank">
                                             support@listenlayer.com
                                        </a>
                                   </li>
                                   <li>
                                        Activities relevant to the data transferred under these SCCs: The performance of the services described in the
                                        Terms of Service.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>Description of Transfer:</b>
                              </p>
                              <ul>
                                   <li>Categories of Data Subjects: Users of the Service and the Customer’s Customer Data.</li>
                                   <li>
                                        Categories of Personal Data: Name, contact information, demographic information, website behavioral activity,
                                        sales engagement activity, employment information, and other information provided by the Data Controller’s use
                                        of the Service.
                                   </li>
                                   <li>
                                        Sensitive Data Transferred: No sensitive data is intended to be transferred unless done so unexpectedly
                                        through the Data Controller’s use of the Service.
                                   </li>
                                   <li>The frequency of the Transfer: Continuous</li>
                                   <li>
                                        Nature of the Processing: The performance of the Service and activities described in the Terms of Service and
                                        this DPA.
                                   </li>
                                   <li>
                                        Purpose(s) of the Data Transfer and Further Processing: The performance of the Service and activities
                                        described in the Terms of Service and this DPA.
                                   </li>
                                   <li>Period for which the Personal Data will be Retained: During the term of the Terms of Service and this DPA.</li>
                                   <li>
                                        For transfers to (sub-) processors, also specify subject matter, nature, and duration of processing: The
                                        performance of the Service and activities described in the Terms of Service and this DPA.
                                   </li>
                              </ul>
                              <p>
                                   <b>Competent Supervisory Authority:</b> The data protection authority of the EU Member State in which the exporter
                                   is established.
                              </p>
                              <h3>Exhibit B</h3>
                              <p>
                                   Information Required under Annex II of the SCCs, technical and organizational measures to ensure the security of
                                   data:
                              </p>
                              <p className="no-padding">
                                   <b>Data Security Measures:</b>
                              </p>
                              <ul>
                                   <li>
                                        Encryption: We employ industry standard encryption technologies for data in transit and data at rest, ensuring
                                        that all Customer Data is secured by such technologies.
                                   </li>
                                   <li>
                                        Access Control: Access to Customer Data is strictly controlled. We implement role-based access controls,
                                        ensuring that only authorized personnel with a legitimate need can access specific data sets.
                                   </li>
                                   <li>
                                        Data Minimization: We adhere to the principle of data minimization, processing only the data necessary for the
                                        specified purposes and ensuring that data is not retained beyond its required use.
                                   </li>
                              </ul>

                              <p className="no-padding">
                                   <b>Physical Security Measures:</b>
                              </p>
                              <ul>
                                   <li>
                                        Facility Access Control: Our facilities utilize standard security and monitoring practices to ensure limited
                                        and controlled access.
                                   </li>
                                   <li>are secured with biometric access controls, surveillance systems, and 24/7 security personnel.</li>
                                   <li>
                                        Protection Against Environmental Risks: Facilities used to house our data and systems have standard protection
                                        in place to mitigate against environmental and natural risks such as water and fire damage.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>Organizational Measures:</b>
                              </p>
                              <ul>
                                   <li>
                                        Data Protection Officer: We have appointed a Data Protection Officer (DPO) who oversees all data protection
                                        matters, managing compliance with data protection laws and regulations
                                   </li>
                                   <li>
                                        Training and Awareness Programs: All staff undergo regular data protection and security training to reinforce
                                        responsibilities and best practices.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>IT Security Measures:</b>
                              </p>
                              <ul>
                                   <li>Network Security: Our network is protected with firewalls, intrusion detection, and prevention systems.</li>
                                   <li>Audits and Assessments: We conduct internal and external security audits to identify and mitigate risks.</li>
                                   <li>
                                        Patch Management: We schedule and manage regular system updates and patches to update software and systems to
                                        the latest versions to mitigate vulnerabilities.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>Incident Response and Data Breach Management:</b>
                              </p>
                              <ul>
                                   <li>
                                        Incident Response Plan: We maintain an incident response plan for efficiently handling data breaches and
                                        security incidents.
                                   </li>
                                   <li>
                                        Notification Procedures: In the event of a data breach, we have established procedures for promptly notifying
                                        the Data Controller as required.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>Data Lifecycle Management:</b>
                              </p>
                              <ul>
                                   <li>
                                        Data Retention and Disposal Policies: Our data retention policies ensure that Customer Data is not kept longer
                                        than necessary, and disposal is conducted securely and irreversibly.
                                   </li>
                                   <li>
                                        Backup and Recovery: We maintain regular backups of data and have a tested recovery process to ensure data
                                        availability and integrity.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>Sub-processor Management:</b>
                              </p>
                              <ul>
                                   <li>
                                        Sub-processor Agreements: Our sub-processors are bound by data protection agreements that uphold the same
                                        level of data protection and security as we commit to.
                                   </li>
                                   <li>
                                        Sub-processor Reviews and Monitoring: We regularly review and monitor our sub-processors to ensure ongoing
                                        compliance with data protection obligations.
                                   </li>
                              </ul>
                              <p className="no-padding">
                                   <b>Measures for Data Transfer Protection:</b>
                              </p>
                              <ul>
                                   <li>
                                        International Transfer Safeguards: For international data transfers, we implement appropriate safeguards such
                                        as Standard Contractual Clauses and ensure compliance with relevant Data Privacy Laws.
                                   </li>
                                   <li>
                                        Secure Data Transfer Protocols: We utilize secure, industry-standard protocols for all data transfers to
                                        ensure data integrity and security.
                                   </li>
                              </ul>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default DataProcessingAddendum
